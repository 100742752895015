import Api from './Api'

export default {
    get() {
        return Api().get(`order/myorders`);
    },
    getOne(idEvento) {
        return Api().get(`order/myorders/evento/${idEvento}/status`);
    },
    getPrice(data) {
        return Api().post(`order/price`, data);
    },
    getStatus() {
        return Api().get(`order/my-status-orders`);
    },
    post(payload) {
        return Api().post(`order/add`, payload);
    },
}