<template>
    <div>
        <HeaderComponent />
        <section class="__sides min-h-screen w-screen py-12">
            <h1 class="mb-2 text-center font-sans text-4xl font-bold">Inscrições SBU-SP</h1>
            <p class="mb-6 text-center">Escolha o curso que deseja adquirir</p>
            <div class="mx-auto grid max-w-screen-lg grid-cols-1 gap-5 p-5 sm:grid-cols-2 md:grid-cols-3 lg:gap-10">
                <SingleProductComponent v-for="product in getProducts" :product="product" :key="product.id"/>
            </div>
        </section>
    </div>
</template>
<script>
    import {
        mapActions,
        mapGetters
    } from 'vuex';
    import HeaderComponent from '../components/HeaderComponent.vue';
    import SingleProductComponent from '../components/product/SingleProductComponent.vue';
    import 'vue3-carousel/dist/carousel.css'
    export default {
        name: 'HomeView',
        components: {
            HeaderComponent,
            SingleProductComponent
        },
        methods: {
            ...mapActions(['fetchProducts']),
        },
        created() {
            this.fetchProducts();
            localStorage.removeItem('cupom');
        },
        computed: {
            ...mapGetters(['getProducts']),
        },
    }
</script>
<style lang="scss">
    /* SCROLLBAR */
    ::-webkit-scrollbar { /* width */
    width: 2px;
    }
    ::-webkit-scrollbar-track { /* Track */
    background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb { /* Handle */
    background: #dfdfdf;
    }
    ::-webkit-scrollbar-thumb:hover { /* Handle on hover */
    background: #d8d8d8;
    }
    /* FIM SCROLLBAR */
</style>