<template>
        <div @click="clickEvent(product)"  :class="isAfterCreationDate ? '': 'opacity-40 border-gray-300'"
            class="group h-full overflow-hidden cursor-pointer rounded-lg border-2 border-gray-200 border-opacity-60 shadow-lg relative pb-12">
            <img class="w-full transform object-cover object-center transition duration-500 ease-in-out group-hover:scale-105 h-36 lg:h-48"
                :src="require('@/assets/'+product.image)"
                alt="blog" />
            <h2 class="flex justify-start items-center title-font cursor-pointer px-6 pt-4 pb-1 text-xs font-semibold uppercase tracking-widest text-orange-600 hover:font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 010 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 010-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375z" />
                </svg>
                R$ {{ product.price }}  
                <span v-if="product.price != product.priceSocio" class="text-[10px] text-white bg-orange-400 px-2 mx-2 py-0.5">
                SOCIOS R$ {{ product.priceSocio }}
                </span>
            </h2>
            <div class="1 px-6 ">
                <h1 class="title-font mb-2 inline-block text-lg capitali font-extrabold tracking-wide text-gray-800">
                    {{ product.name }}
                </h1>
                <p class="line-clamp-3 hover:line-clamp-4 text-sm text-gray-500">
                    {{ product.description }}
                </p>
            </div>
            <div v-if="product && product.Payments && product.Payments.length" class="flex flex-wrap w-full items-center justify-center py-1 absolute bottom-0 bg-white border-t">
                <div class="mt-1">
                    <span class="mr-3 ml-auto inline-flex items-center py-1 pr-3 text-sm leading-none text-gray-400 md:ml-0 lg:ml-auto">
                        <span v-if="product.Payments[0].status === 'pending'" class="flex items-center gap-1 text-yellow-600">
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clip-rule="evenodd" /></svg>
                            Pendente
                        </span>
                        <span v-else-if="product.Payments[0].status === 'approved'" class="flex items-center gap-1 text-green-600">
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            Aprovado
                        </span>
                        <span v-else-if="product.Payments[0].status === 'rejected'" class="flex items-center gap-1 text-red-600">
                            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                            Recusado
                        </span>
                    </span>
                </div>
            </div>
        </div>
</template>

<script>
import { mapGetters } from 'vuex';
    export default {
        name: 'SingleProduct',
        props: {
            product: {
                type: Object,
                required: true
            },
        },
        methods: {
            clickEvent(event){
                const currentDate = new Date();
                const creationDate = new Date(event.createdAt);
                if(currentDate > creationDate){
                    this.$router.push('evento/' + event.tag);
                }else{
                    const date = new Date(event.createdAt);
                    const day = date.getDate();
                    const month = date.getMonth() + 1; // Adicionamos 1, pois os meses são indexados em zero
                    const year = date.getFullYear();

                    this.SET_ALERT({ heading: "error", message: `Inscrição a partir de ${day}/${month}/${year} `  });
                }
            }
        },
        computed: {
            ...mapGetters(['getOrders', 'getOrderLoading']),
            isAfterCreationDate() {
                const currentDate = new Date();
                const creationDate = new Date(this.product.createdAt);
                return currentDate > creationDate;
            }
        }
    }
</script>