import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/eventos',
    name: 'Evento',
    component: HomeView
  },
  {
    path: '/auth',
    name: 'Authentication',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/profile/account',
    name: 'Profile',
    component: () => import('@/views/ProfileView.vue')
  },
  {
    path: '/notification',
    name: 'Notification',
    component: () => import('@/views/NotificationView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/lista-espera',
    name: 'Register Espera',
    component: () => import('@/views/RegisterVagasEsgotadas.vue')
  },
  {
    path: '/evento/secretarias/register',
    name: 'Register Secretarias',
    component: () => import('@/views/RegisterSecretariasView.vue')
  },

  {
    path: '/evento/:tag',
    name: 'Evento',
    component: () => import('@/views/InscricaoEvento.vue'),
  },
  {
    path: '/evento/secretarias',
    name: 'Evento Secretarias',
    component: () => import('@/views/InscricaoSecretarias.vue'),
  },
  {
    path: '/password-reset/:tokenPass?',
    name: 'Reset Password',
    component: () => import('../views/PasswordResetView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error 404',
    component: () => import('@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
