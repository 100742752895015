// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import product from './modules/product';
import notifications from './modules/notification';
import localidadeIBGE from './modules/localidadeIBGE';
import email from './modules/email';
import order from './modules/order';
import payment from './modules/payment';
import secretaria from './modules/secretaria';
import cupom from './modules/cupom';
import current from './modules/current';
import listaEspera from './modules/listaEspera';

// Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        product,
        notifications,
        localidadeIBGE,
        email,
        order,
        payment,
        secretaria,
        cupom,
        current,
        listaEspera
    }
})
