<template>
<div class="vue-modal">
    <div id="authentication-modal" @click.self="SHOW_MODAL_LOGIN({payload: false, redirect: false})" tabindex="-1" aria-hidden="true"
      class="backdrop-blur-sm flex bg-gray-200 bg-opacity-50 overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[99] w-full md:inset-0 h-modal md:h-full justify-center items-center">
      <div class="flex items-center relative p-4 w-full max-w-md h-[100vh] ">
        <div class="relative bg-white shadow">
          <button @click="SHOW_MODAL_LOGIN({payload: false, redirect: false})" type="button"
            class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 text-sm p-1.5 ml-auto inline-flex items-center"
            data-modal-toggle="authentication-modal">
            <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </button>
          <div class="py-6 px-6 lg:px-8">
            <div class="flex justify-center mb-4">
              <img alt="Logo" :src="logo" class="h-24 text-center">
            </div>
              <p class="mb-3 text-sm">Utilize os mesmos dados de login da SBU Nacional</p>
            <form @submit.prevent="login" class="space-y-6">
              <div class="w-full mt-10">
                <label for="email" class="text-sm font-medium">Email</label>
                <input v-model="email" class="input-style" type="email" placeholder="E-mail" id="email" aria-label="Email" />
              </div>
              <div class="w-full mt-2">
                <label for="password" class="text-sm font-medium">Senha</label>
                <input v-model="password" class="input-style" type="password" id="password" aria-label="password" />
              </div>
              <div class="flex justify-end">
                <a href="https://siasbu.org.br/authentication/password-recovery" target="_blank" class="text-sm text-blue-700 hover:underline">Esqueci minha senha</a>
              </div>
              <button :class="getLoadingUser && 'cursor-not-allowed'" @click.prevent="submitLoginAPI" class="flex w-full justify-center items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 transform focus:outline-none bg-blue-700 rounded hover:bg-blue-600">
                <div class="flex scale-50" v-if="getLoadingUser">
                  <SpinnerComponent class="m-auto h-6" />
                </div>
                <span v-else class="mx-4 text-center">
                    Entrar
                </span>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import logoplataforma from '@/assets/logo_sbu_sp.png';
  import SpinnerComponent from '@/components/helper/SpinnerComponent.vue'
  import { mapActions, mapGetters, mapMutations } from 'vuex';

  export default {
    name: 'LoginViewAPI',
    components: {
            SpinnerComponent
        },
    data() {
      return {
        logo: logoplataforma,
        email: "",
        password: ""
      }
    },
    methods: {
      ...mapActions(['loginUserAPI']),
      ...mapMutations(['SHOW_MODAL_LOGIN']),

      submitLoginAPI(){
        if(!this.getLoadingUser){
          if(this.email && this.password){
            this.loginUserAPI({
              email: this.email,
              password: this.password
            });
          }
        }
        }
    },
    computed: {
        ...mapGetters(['getLoadingUser'])
      }
  }
</script>