import ProductService from '../../services/ProductService'

const state = () => ({
    product: null,
    topProducts: [],
    pages: 0,
    page: 1,
    products: [],
    relatedProduct: [],
    shipping: 100,
    cart: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [],
    liked: localStorage.getItem('likedItems') ? JSON.parse(localStorage.getItem('likedItems')) : [],
    productLoading: false
})
  
const mutations = {
    SET_PRODUCTS: (state, payload) => {
        state.products = payload.products;
    },
    SET_PRODUCT: (state, product) => {
        state.product = product;
    },
    SET_PRODUCT_LOADING: (state, payload) => {
        state.productLoading = payload;
    },
    ADD_TO_CART: (state, newProduct) => {
        state.cart = newProduct.evento
        localStorage.setItem('cartItems', JSON.stringify(newProduct.evento));
    },
    ADD_TO_LIKED: (state, newProduct) => {
        const newArray = state.liked.filter(product => product._id !== newProduct._id);
        state.liked = [newProduct, ...newArray]
        localStorage.setItem('likedItems', JSON.stringify([newProduct, ...newArray]));
    },
    REMOVE_FROM_CART: (state, SelectProduct) => {
        const newArray = state.cart.filter(product => product._id !== SelectProduct._id);
        state.cart = newArray;
        localStorage.setItem('cartItems', JSON.stringify(newArray));
    },
    REMOVE_FROM_LIKED: (state, SelectProduct) => {
        const newArray = state.liked.filter(product => product._id !== SelectProduct._id);
        state.liked = newArray;
        localStorage.setItem('likedItems', JSON.stringify(newArray));
    },
    SET_VAGAS_PRODUCT: (state, payload) => {
        state.product.vagas = payload
    }
}

const actions = {
    async fetchProducts({commit}){
        try {
            commit('SET_PRODUCT_LOADING', true);
            const response = await ProductService.get();
            commit('SET_PRODUCTS', {...response.data, products: response.data.products});
            commit('SET_PRODUCT_LOADING', false);
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchProductVagas({commit}, tag){
        try {
            commit('SET_PRODUCT_LOADING', true);
            const response = await ProductService.getVagas(tag);
            commit('SET_PRODUCT', response.data)
            commit('SET_CURRENT', response.data.id)
            commit('SET_PRODUCT_LOADING', false);
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchProductVagasSecretarias({commit}, tag){
        try {
            commit('SET_PRODUCT_LOADING', true);
            const response = await ProductService.getVagasSecretarias(tag);
            commit('SET_PRODUCT', response.data)
            commit('SET_CURRENT', response.data.id)
            commit('SET_PRODUCT_LOADING', false);
        } catch (error) {
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getEvento: state => state.cart,
    getProducts: state => state.products,
    getProduct: state => state.product,
    getProductLoading: state => state.productLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};