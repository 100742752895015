const state = () => ({
    currentLoading: false,
    currentSteps: (() => {
        try {
            return JSON.parse(localStorage.getItem('currentSteps')) || [];
        } catch (error) {
            localStorage.removeItem('currentSteps');
            return [];
        }
    })(),
})
  
const mutations = {
    NEXTSTEP(state, idEvento) {
        state.currentSteps.find(item => item.idEvento === idEvento).currentStep++
        localStorage.setItem('currentSteps', JSON.stringify(state.currentSteps));
    },
    PREVIOUSSTEP(state, idEvento) {
        state.currentSteps.find(item => item.idEvento === idEvento).currentStep--
        localStorage.setItem('currentSteps', JSON.stringify(state.currentSteps));
    },
    SET_CURRENT: (state, idEvento) => {
        state.currentSteps = [
            {
                idEvento,
                currentStep: 1
            }
        ]
        localStorage.setItem('currentSteps', JSON.stringify(state.currentSteps));
    },
}

const actions = {
    nextStep({ commit }, idEvento) {
        commit('NEXTSTEP', idEvento)
    },
    previousStep({ commit }, idEvento) {
        commit('PREVIOUSSTEP', idEvento)
    },
}

const getters = {
    getCurrentSteps: state => state.currentSteps,
    getLoadingCurrent: state => state.currentLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};