import ListaEsperaService from '../../services/ListaEsperaService'
import router from '../../router'

const state = () => ({
    loadingListaEspera: false,
    listaEspera: null,
})
  
const mutations = {
    SET_LOADING_LISTA_ESPERA: (state, payload) => {
        state.loadingListaEspera = payload
    },
    SET_LISTA: (state, payload) => {
        state.listaEspera = payload.list
    }
}

const actions = {
    async fetchMyListaEspera({commit}){
        try{
            commit('SET_LOADING_LISTA_ESPERA', true)
            const response = await ListaEsperaService.getMyList();
            commit('SET_LISTA', response.data)
            commit('SET_LOADING_LISTA_ESPERA', false)
        } catch (error) {
            commit('SET_LOADING_LISTA_ESPERA', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerListaEspera({commit, dispatch}, { idEvento }){
        try{
            commit('SET_LOADING_LISTA_ESPERA', true);
            const response = await ListaEsperaService.registrarListaEspera(idEvento);
            dispatch('fetchMyListaEspera');
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/`});
            commit('SET_LOADING_LISTA_ESPERA', false);
            return true;
        }catch(error){
            commit('SET_LOADING_LISTA_ESPERA', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
}

const getters = {
    getListaEspera: state => state.listaEspera,
    getLoadingListaEspera: state => state.loadingListaEspera,
}

export default {
    state,
    getters,
    mutations,
    actions
};