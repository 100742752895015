import LocalidadeService from '../../services/LocalidadeService'

const state = () => ({
    estados: [],
    cidades: localStorage.getItem('municipios') ? JSON.parse(localStorage.getItem('municipios')) : [],
})

const mutations = {
    SET_ESTADOS: (state, estados) => {
      console.log('SET_ESTADOS -> ', estados)
      state.estados = estados;
    },
    SET_CIDADES: (state, cidades) => {
      localStorage.setItem('municipios', JSON.stringify(cidades));
      state.cidades = cidades;
    }
}
  
const actions = {
  async carregarEstados({ commit }) {
    // const response = await axios.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
    const response = await LocalidadeService.getEstados();
    commit('SET_ESTADOS', response.data)
  },

  async carregarCidades({ commit }, uf) {
    // const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`)
    const response = await LocalidadeService.getCidades(uf);
    commit('SET_CIDADES', response.data)
  }
}

const getters = {
  getEstados: state => state.estados,
  getCidades: state => state.cidades,
}

export default {
  state,
  getters,
  mutations,
  actions
};