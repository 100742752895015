<template>
    <footer aria-label="Site Footer" class="bg-gray-200 border-t">
    <div class="mx-auto max-w-screen-xl py-12 px-4 sm:px-6 lg:px-8">
        <div class="lg:flex lg:items-start lg:gap-8 justify-end">
        <div class="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">

            <ul class="col-span-2 list-none flex justify-center gap-6 lg:col-span-5 lg:justify-end">
                <li>
                    <a href="https://www.facebook.com/sbusp.oficial/" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                    <span class="sr-only">Facebook</span>

                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"></path>
                    </svg>
                    </a>
                </li>

                <li>
                    <a href="https://www.instagram.com/sbusp.oficial/" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                    <span class="sr-only">Instagram</span>

                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
                    </svg>
                    </a>
                </li>

                <li>
                    <a href="https://twitter.com/sbusp_oficial" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                    <span class="sr-only">Twitter</span>

                    <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                    </svg>
                    </a>
                </li>

                <li>
                    <a href="https://www.linkedin.com/company/sociedade-brasileira-de-urologia-s%C3%A3o-paulo/" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                    <span class="sr-only">linkedin</span>

                    <svg class="h-5 w-5" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" viewBox="-271 283.9 256 235.1" xml:space="preserve">
                        <rect x="-264.4" y="359.3" width="49.9" height="159.7"></rect><path d="M-240.5,283.9c-18.4,0-30.5,11.9-30.5,27.7c0,15.5,11.7,27.7,29.8,27.7h0.4c18.8,0,30.5-12.3,30.4-27.7   C-210.8,295.8-222.1,283.9-240.5,283.9z"></path><path d="M-78.2,357.8c-28.6,0-46.5,15.6-49.8,26.6v-25.1h-56.1c0.7,13.3,0,159.7,0,159.7h56.1v-86.3c0-4.9-0.2-9.7,1.2-13.1   c3.8-9.6,12.1-19.6,27-19.6c19.5,0,28.3,14.8,28.3,36.4V519h56.6v-88.8C-14.9,380.8-42.7,357.8-78.2,357.8z"></path>
                    </svg>
                    </a>
                </li>

                <li>
                    <a href="https://www.youtube.com/channel/UC5jd08ROaiRpW4QqC-hi5Sg" rel="noreferrer" target="_blank" class="text-gray-700 transition hover:opacity-75">
                    <span class="sr-only">Youtube</span>

                    <svg class="h-7 w-7" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M24.325 8.309s-2.655-.334-8.357-.334c-5.517 0-8.294.334-8.294.334A2.675 2.675 0 0 0 5 10.984v10.034a2.675 2.675 0 0 0 2.674 2.676s2.582.332 8.294.332c5.709 0 8.357-.332 8.357-.332A2.673 2.673 0 0 0 27 21.018V10.982a2.673 2.673 0 0 0-2.675-2.673zM13.061 19.975V12.03L20.195 16l-7.134 3.975z"></path>
                    </svg>
                    </a>
                </li>
            </ul>
        </div>
        </div>
        <div class="mt-4 border-t border-gray-100 pt-8">
        <div class="sm:flex sm:justify-between">
            
            <nav aria-label="Footer Navigation - Support" class="mt-8 sm:mt-0">
            <ul class="flex flex-wrap list-none justify-start gap-4 text-xs lg:justify-end">
                <li class="text-gray-500 transition hover:opacity-75">
                    <span class="font-semibold">
                        Wagner Matheus
                    </span>
                    <br>
                    Diretor-presidente da SBU-SP biênio 2024-2025
                </li>
            </ul>
            </nav>
            <p class="text-xs text-gray-500 mt-2">
            © {{ new Date().getFullYear() }} . SBU-SP. Todos os direitos reservados.
            </p>
        </div>
        </div>
    </div>
    </footer>
</template>

<script>
    export default {
        name: 'FootterComponnet',
        data: () => {
            return {
            }
        },
    }
</script>