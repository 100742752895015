import NotificationService from '../../services/NotificationService'

const state = () => ({
    pages: 0,
    page: 1,
    notificationLoading: false,
    notifications: localStorage.getItem('notifications') ? JSON.parse(localStorage.getItem('notifications')) : [],
})
  
const mutations = {
    SET_NOTIFICATIONS: (state, payload) => {
        localStorage.setItem('notifications', JSON.stringify(payload.notifications));
        localStorage.setItem('token', payload.token)
        state.notifications = payload.notifications;
        // state.page = payload.page;
        // state.pages = payload.pages;
    },
    UPDATE_NOTIFICATIONS: (state, payload) => {
        localStorage.setItem('notifications', JSON.stringify(payload.notifications));
        state.notifications = payload.notifications;
    },
    SET_LOADING_NOTIFICATIONS: (state, payload) => {
        state.notificationLoading = payload
    },
}

const actions = {
    async notificationsUser({commit}){
        try {
            commit('SET_LOADING_NOTIFICATIONS', true);
            const response = await NotificationService.get(1);
            commit('SET_NOTIFICATIONS', response.data);
            commit('SET_LOADING_NOTIFICATIONS', false);
        } catch (error) {
            commit('SET_LOADING_NOTIFICATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateNotification({commit}, idNotification){
        try {
            const response = await NotificationService.update(idNotification);
            commit('UPDATE_NOTIFICATIONS', response.data);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

const getters = {
    getNotifications: state => state.notifications,
    getLoadingNotification: state => state.notificationLoading,
    countUnreadNotifications: (state) => {
        return state.notifications.filter((item) => item.visualizado === 'N').length;
    }
}

export default {
    state,
    getters,
    mutations,
    actions
};