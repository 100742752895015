import Api from './Api'

export default {
    get() {
        return Api().get(`products`);
    },
    getOne(tag) {
        return Api().get(`products/${tag}`);
    },
    getVagas(tag) {
        return Api().get(`products/vagas/${tag}`);
    },
    getVagasSecretarias(tag) {
        return Api().get(`products/vagasSecretarias/${tag}`);
    }
}