import Api from './Api'

export default {
    get(){
    return Api().get(`secretaria`);
    },
    create(credentials){
        return Api().post('secretaria/register', credentials);
    },
    update(credentials){
        return Api().put('secretaria/update', credentials);
    },
    delete(idSecretaria){
        return Api().delete(`secretaria/delete/${idSecretaria}`);
    },
}