import SecretariaService from '../../services/SecretariaService'

const state = () => ({
    secretariaLoading: false,
    secretarias: [],
    secretariaData: {
        firstName: 'Mariana',
        lastName: 'Secretaria Teste',
        email: 'mary-usu-2@test.com.br',
        phone: '14986425678',
        cpf: '801.952.070-84'
    }, 
})
  
const mutations = {
    SET_SECRETARIA: (state, payload) => {
        state.secretarias = payload.secretarias;
    },
    SET_SECRETARIA_DATA: (state, payload) => {
        state.secretariaData = payload;
    },
    SET_LOADING_SECRETARIA: (state, payload) => {
        state.secretariaLoading = payload
    },
}

const actions = {
    async carregarSecretariasUser({commit}){
        try {
            commit('SET_LOADING_SECRETARIA', true);
            const response = await SecretariaService.get();
            commit('SET_SECRETARIA', response.data);
            commit('SET_LOADING_SECRETARIA', false);
        } catch (error) {
            commit('SET_LOADING_SECRETARIA', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerSecretaria({commit, dispatch}, secretaria){
        try {
            commit('SET_LOADING_SECRETARIA', true);
            await SecretariaService.create(secretaria);
            commit('SET_ALERT', {
                heading: 'success',
                message: "Nova Secretaria Adcionada"
            });
            await dispatch('carregarSecretariasUser');

            commit('SET_LOADING_SECRETARIA', false);
        } catch (error) {
            commit('SET_LOADING_SECRETARIA', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateSecretaria({commit, dispatch}, Address){
        try {
            commit('SET_LOADING_SECRETARIA', true);
            const response = await SecretariaService.update(Address);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            await dispatch('carregarSecretariasUser');

            commit('SET_LOADING_SECRETARIA', false);
        } catch (error) {
            commit('SET_LOADING_SECRETARIA', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteSecretaria({commit, dispatch}, idAddress){
        try {
            commit('SET_LOADING_SECRETARIA', true);
            const response = await SecretariaService.delete(idAddress);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
             await dispatch('carregarSecretariasUser');

            commit('SET_LOADING_SECRETARIA', false);
        } catch (error) {
            commit('SET_LOADING_SECRETARIA', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getSecretarias: state => state.secretarias,
    getSecretariaData: state => state.secretariaData,
    getLoadingSecretaria: state => state.secretariaLoading,
}

export default {
    state,
    getters,
    mutations,
    actions
};