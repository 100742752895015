import AuthService from '../../services/AuthService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '',
    token: localStorage.getItem('token') || null,
    user: (() => {
        try {
            return JSON.parse(localStorage.getItem('user')) || null;
        } catch (error) {
            localStorage.removeItem('user');
            return null;
        }
      })(),
    userSocio: false,
    personalData: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        cpf: '',
        authorize: ''
    }, 
    completed: false,
    showModelLogin: false,
    password_slep: 1,
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));
        
        state.isAuthenticated = true;
        state.token = payload.token
        state.user = payload.user
        if(router.currentRoute.value.path === '/auth' && !state.redirect){
            router.push({path: `/`});
        }else {
            router.push({path: state.redirect});
            state.redirect = null;
        }
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload.user));

        state.isAuthenticated = true;
        state.token = payload.token
        state.user = payload.user;
    },
    SHOW_MODAL_LOGIN: (state, {payload, redirect = false}) => {
        state.showModelLogin = payload
        if(redirect){
            router.push({path: `/register`});
        }
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('user');
        localStorage.removeItem('cupom');
        localStorage.removeItem('token');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('likedItems');
        localStorage.removeItem('currentSteps');

        state.orders = [];
        state.user = null;
        state.token = null;
        state.cart = [];
        state.liked = [];
        state.cidades = [];
        state.currentSteps = [];
        state.isAuthenticated = false;
        state.products = [];
        state.product = null;

        if(router.currentRoute.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    SETPERSONALDATA(state, personalData) {
        state.personalData = personalData
    },
    SETCOMPLETED(state, completed) {
        state.completed = completed
    },
    SLIP_NEW_PASSWORD: (state, slep) => {
        state.password_slep = slep;
    },
}

const actions = {
    async loginUser({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async loginUserAPI({commit}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const responseAPI = await AuthService.login_API_SBU(credentials);
            const response = await AuthService.associarUser(responseAPI.data.member);
            commit('SHOW_MODAL_LOGIN', false);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            // commit('LOG_USER_OUT');
            if(error.response.data && error.response.data.errors && error.response.data.errors.message){
                commit('SET_ALERT', {
                    heading: 'error',
                    message: error.response.data.errors.message
                });
            }
        }
    },
    async registerUser({commit}, { credentials, idEvento }){
        try{
            commit('SET_LOADING_USER', true);
            const response = await AuthService.create(credentials);
            commit('NEXTSTEP', idEvento);
            commit('SET_USER', response.data);
            commit('SET_LOADING_USER', false);
            return true;
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
    async fetchUser({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getUser();
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateUser({commit}, payload, idEvento){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.update(payload);
            await commit('AUTH_USER', response.data);
            commit('NEXTSTEP', idEvento);
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async verifyUser({state, commit}){
        try{
            if (state.user == null) return false
            if (state.user.accounType !== 'SBU-SP') return false
            if (state.user.order_status == 'Inadimplente') {
                commit('SET_ALERT', {
                    heading: 'Erro',
                    message: "Existem pendências em seu cadastro. Para regularizá-las, entre em contato com a Secretaria da SBU"
                });
                return false
            } else{
                return true;
            }
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    savePersonalData({ commit }, personalData) {
        commit('SETPERSONALDATA', personalData)
    },
    submitForm({ commit }) {
        commit('SETCOMPLETED', true)
    },
    async newPessVerificarEmail({commit, dispatch}, email){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.newPassVerifyEmail(email);
            dispatch('sendNewPassword', response.data, { root: true });
            commit('SET_ALERT', {
                heading: 'success',
                message: 'Enviamos um e-mail para a auteração de sua senha.'
            });
            commit('SLIP_NEW_PASSWORD', 2)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async newPessVerificarToken({commit}, token){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.newPassVerifyToken(token);
            if(response.data.valido == true){
                commit('SLIP_NEW_PASSWORD', 3)
            }
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async submitNewPass({commit}, credentials){
        try {
            commit('SET_LOADING_USER', true)
            const response = await AuthService.newPass(credentials);

            if(response.data.user){
                commit('SET_ALERT', {
                    heading: 'success',
                        message: response.data.message
                    });
                setTimeout(() => {
                    commit('REDIRECT', '/');
                    commit('SET_USER', response.data);
                    commit('SLIP_NEW_PASSWORD', 1)

                }, 3000);
            }
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false)
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getUser: state => state.user,
    getLoadingUser: state => state.loadingUser,
    getPersonalData: state => state.personalData,
    isCompleted: state => state.completed,
    getShowModalLogin: state => state.showModelLogin,
    getPasswordSlep: state => state.password_slep
}

export default {
    state,
    getters,
    mutations,
    actions
};