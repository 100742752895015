import PaymentService from '@/services/PaymentService'

const state = () => ({
    payment: [],
    selectedPayment: false,
    paymentLoading: false,
    total: 0.00
})
  
const mutations = {
    GO_BACK_TYPES_PAYMENT: (state) => {
        state.selectedPayment = false;
    },
    SET_PAYMENT: (state, {status_payment, type}) => {
        state.payment = status_payment;
        state.selectedPayment = type;
    },
    UPDATE_PAYMENT: (state, payment) => {
        state.payment = payment;
    },
    SET_LOADING_PAYMENT: (state, payload) => {
        state.paymentLoading = payload
    },
    SET_TOTAL: (state, total) => {
        state.total = total
    }
}

const actions = {
   
    async createPaymentPix({rootState, commit}, order){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const { OrderId, description, external_reference, total } = order
            const user = rootState.user.user;
            var CupomId = null;
            if(rootState.cupom && rootState.cupom.cupom && rootState.cupom.cupom.id) {
                CupomId = rootState.cupom.cupom.id
            }
            const body = {
                "transaction_amount": total,
                description,
                "payment_method_id": "pix",
                "payer": {
                    "first_name": user.firstName,
                    "last_name": user.last_name,
                    "email": user.email,
                    "identification": {
                        "type": "CPF",
                        "number": String(user.cpf)
                    }
                },
                external_reference,
                // "notification_url": `${process.env.VUE_APP_URL_SERVER}/api/payment/webhook`,
                "notification_url": `https://server.sbu-sp.org.br/api/payment/webhook`,
            }
            const response = await PaymentService.createPaymentPix(body);
            const responseMP = response.data.body
            
            PaymentService.savePaymentPix({
                transaction_id: responseMP.id,
                external_reference: responseMP.external_reference,
                status: responseMP.status,
                description: responseMP.description,
                installments: responseMP.installments,
                amount:  responseMP.transaction_amount,
                identificationNumber: user.cpf.replace(/\D/g, ''),
                cardholderEmail: user.email,
                paymentMethodId: 'pix',
                OrderId,
                CupomId
            });
            
            commit('SET_PAYMENT', { status_payment: responseMP, type:'pix'});
            commit('SET_LOADING_PAYMENT', false);

        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPaymentCard({rootState, commit}, body){
        const { OrderId, description, external_reference } = body.Order
        try {
            commit('SET_LOADING_PAYMENT', true);
            const user = rootState.user.user;
            var CupomId = null;
            if(rootState.cupom && rootState.cupom.cupom && rootState.cupom.cupom.id) {
                CupomId = rootState.cupom.cupom.id
            }
            body.external_reference = external_reference
            body.cardholderEmail = user.email;
            body.description = description;
            body.OrderId = OrderId;
            // body.notification_url = `${process.env.VUE_APP_URL_SERVER}/api/payment/webhook`;
            body.notification_url = `https://server.sbu-sp.org.br/api/payment/webhook`;

            const response = await PaymentService.createPaymentCard(body);
            
            PaymentService.savePaymentCredit({orderPayment: body, statusPayment: response.data, CupomId});
            
            commit('SET_PAYMENT', { status_payment: response.data, type:'card' });

            if(response.data.status == 'approved'){
                commit('NEXTSTEP', OrderId)
            }
            commit('SET_LOADING_PAYMENT', false);
        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchVerifyPayment({ commit }, transaction_id, idEvento){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const { data }  = await PaymentService.verifyOne(transaction_id);
            const { status } = data.payment;

            if(status == 'approved'){
                commit('SET_PAYMENT', { status_payment: data.payment, type:'pix' });
                commit('NEXTSTEP', idEvento)
            }
            commit('SET_LOADING_PAYMENT', false);
        } catch (error) {
            console.log(error)
        }
    },
}

const getters = {
    getPayment: state => state.payment,
    getLoadingPayment: state => state.paymentLoading,
    getSelectedPayment: state => state.selectedPayment,
    getTotal: state => state.total
}

export default {
    state,
    getters,
    mutations,
    actions
};