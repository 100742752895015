<template>
    <header>
        <div class="h-[5.5em]"></div>

        <div class="group relative">

            <div :class="{ 'shadow-md': isScrolled }" class="border-b transition-shadow fixed w-full bg-white top-0 z-[98]">
                <div class="__sides relative flex items-center justify-between h-[5.5em]">
                    <BurgerMenuIcon @event="handleMenuEvent" class="md:hidden border-none"/>
                    <router-link class="text-2xl center-title font-bold text-gray-800 hover:text-gray-600 lg:text-3xl" to="/">
                        <img alt="Logo" :src="logo" class="h-24 text-center mb-1">
                    </router-link>
                    <nav class="space-x-6 hidden md:flex">
                        <router-link v-for="link in mainLinks" :key="link.name" class="__link" :to="link.path">{{link.name}}</router-link>
                    </nav>
                    <div class="flex items-center space-x-2 lg:space-x-6">
                        <router-link v-if="!$store.state.user.user" to="/auth" class="hidden __link md:block">Login</router-link>
                        <router-link v-else  to="/profile/account" class="hidden __link md:block">Minha Conta</router-link>
                        <!-- Sacola -->
                    </div>
                </div>
            </div>
            <!-- Vimeo Dropdown -->
            <div v-show="secundarios" class="hidden group-hover:block delay-100 absolute z-10  bg-white rounded divide-y divide-gray-100 shadow-xl border border-gray-300 w-full">
                <nav id="nav__secundario" class="flex flex-wrap bg-gray-400 justify-center text-xs font-semibold text-white py-2 max-w-full items-center overflow-y-hidden max-h-12">
                    <router-link v-for="( disciplina, index ) in secundarios" :key="index" to="/" class="hidden hover:bg-slate-200 hover:text-gray-500 md:block px-4 py-2 mb-3 max-w-[15rem] truncate">{{ disciplina.nome }}</router-link>
                </nav>
            </div>
            <!-- FIM Vimeo Dropdown -->
        </div>
        <!-- Itens dentro do Burger -->
        <div class="md:hidden" :class="showThisCommponent ? 'block md:hidden' : 'hidden'">
            <div :class="isMenuOpen ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'" class="bg-white fixed z-[98] transition-all duration-300 bottom-0 p-4 divide-y top-[5.5em] min-h-screen w-screen">
                <router-link v-for="link in mainLinks" :key="link.name" class="text-lg py-2 block" :to="link.path" >{{link.name}}</router-link>
                <router-link v-if="!$store.state.user.user" to="/auth" class="text-lg py-2 block">Login</router-link>
                <router-link v-else to="/profile/account" class="text-lg py-2 block">Minha conta</router-link>
            </div>
        </div>
        <!-- Fim dos itens dentro do Burger -->
        <Modal_Login v-show="getShowModalLogin()" class="relative" />
    </header>
</template>

<script>
    import logoplataforma from '@/assets/logo_sbu_sp.png';
    import BurgerMenuIcon from '@/components/header/BurgerMenuIcon.vue';
    import Modal_Login from "@/components/Modal_Login.vue";
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    export default {
        name: 'HeaderComponent',
        components: {
            BurgerMenuIcon,
            Modal_Login
        },
        props: {
            noBackground: {
                Type: Boolean
            },
            noBorder: {
                Type: Boolean
            }
        },
        data() {
            return {
                logo: logoplataforma,
                isMenuOpen: false,
                isScrolled: false,
                showThisCommponent: false,
                mainLinks: [
                    // {
                    //     name: 'Eventos',
                    //     path: '/'
                    // },
                  
                ],
                secundarios: null,
                disciplinas: null,
                projeto: "ensinoSBOT"

            }
        },
        mounted() {
            window.addEventListener("scroll", this.handleScroll);
        },
        // beforeDestroy() {
        //     window.removeEventListener("scroll", this.handleScroll);
        // },
        methods: {
            ...mapActions(['subjects']),
            ...mapGetters(['getLoadingSubject', 'getShowModalLogin']),
            ...mapMutations(['LOG_USER_OUT']),
            handleMenuEvent(evt) {
                if(evt) {
                    this.showThisCommponent = evt
                    return;
                }
                this.isMenuOpen = false;
                setTimeout(() => {
                    this.showThisCommponent = false;
                }, 310);
            },
            updateIsMenuOpen(value) {
                if(value){
                    setTimeout(() => {
                        this.isMenuOpen = value;
                    }, 2);
                }
            },
            handleScroll() {
                this.isScrolled = window.scrollY > 0;
            }
        },
        watch: {
            showThisCommponent(value) {
                this.updateIsMenuOpen(value)
            }
        },
       
    }
</script>

<style>
    @media screen and (max-width: 768px) {
        .center-title {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .__link {
        text-decoration: none;
        position: relative;
    }

    .__link::after {
        bottom: -4px;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        position: absolute;
        background: rgb(31 41 55);
        opacity: 0.6;
        transition: width 0.3s ease 0s, opacity 0.3s ease 0s;
        width: 0;
    }

    .__link:hover::after {
        width: 100%;
        opacity: 0.9;
    }
</style>