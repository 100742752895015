import OrderService from '../../services/OrderService'

const state = () => ({
    orderLoading: false,
    orders: [],
})
  
const mutations = {
    SET_ORDERS: (state, payload) => {
        state.orders = payload
    },
    SET_ORDER: (state, newOrder) => {
        const newArray = state.orders.filter(order => order.OrderId !== newOrder.OrderId);
        state.orders = [newOrder, ...newArray]
    },
    SET_CREATED_ORDER: (state, payload) => {
        state.orders = [payload]
    },
    SET_ORDER_LOADING: (state, payload) => {
        state.orderLoading = payload
    }
}

const actions = {
    async verifyPrice({dispatch, rootState}){
        const cuponCode = rootState.cupom.cupom ? rootState.cupom.cupom.cuponCode : null;
        const response = await OrderService.getPrice({idProduct: rootState.product.cart.id, cuponCode});

        if(response.data.product.id == 3){
            await dispatch('carregarSecretariasUser')
            const secretarias = rootState.secretaria.secretarias;
           
                return (response.data.total * secretarias.length);
        }
        return response.data.total;
    },
    async createOrder({commit, dispatch, rootState}, { typePayment, dataPayment = null }){
        try {
            commit('SET_ORDER_LOADING', true);
            const user = rootState.user.user;
            const cartItem = rootState.product.cart;
            const total = await dispatch('verifyPrice');
            const order = {
                OrderId: cartItem.id,
                description: cartItem.name,
                external_reference: `extRef_${cartItem.tag}_${user.id}`,
                total
            }
            if(typePayment == 'pix'){
                await dispatch('createPaymentPix', order)
            } else if(typePayment == 'card'){
                dataPayment.Order = order;
                await dispatch('createPaymentCard', dataPayment)
            }
            commit('SET_ORDER_LOADING', false);
        } catch (error) {
            commit('SET_ORDER_LOADING', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: "Erro ao gerar a Ordem de Pagamento"
            });
        }
    },
    async fetchMyOrderEvent({ commit }, idEvento){
        try {
            commit('SET_ORDER_LOADING', true);
            const response = await OrderService.getOne(idEvento);
            commit('SET_ORDER', response.data)
            commit('SET_ORDER_LOADING', false);
        } catch (error) {
            commit('SET_ORDER_LOADING', false);
            commit('LOG_USER_OUT');
            // commit('SET_ALERT', {
            //     heading: 'error',
            //     message: error.response.data.message
            // });
        }
    }
}

const getters = {
    getOrderLoading: state => state.orderLoading,
    getOrders: state => state.orders,

}

export default {
    state,
    getters,
    mutations,
    actions
};