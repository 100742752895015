import Api from './Api'

export default {
    createPaymentPix(data){
        return Api().post(`${process.env.VUE_APP_URL_MERCADO_PAGO}/sbu-sp/process_payment_pix`, data);
    },
    createPaymentCard(data){
        return Api().post(`${process.env.VUE_APP_URL_MERCADO_PAGO}/sbu-sp/process_payment`, data);
    },
    savePaymentCredit(data){
        return Api().post('payment/add/credit', data);
    },
    savePaymentPix(data){
        return Api().post('payment/add/pix', data);
    },
    verifyOne(id){
        const data =  {id}
        return Api().post('payment/webhook', {data});
    },
}